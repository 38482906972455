import React from "react";
import ResumeEditor from "../ResumeEditor";

const Edit = () => {
  return (
    <div>
      <ResumeEditor />
    </div>
  );
};

export default Edit;
